import React from 'react'
import styles from './styles.module.scss'
import htmr from 'htmr'

type SectionHeaderProps = {
  byline?: string
  title?: string
  className?: string
  inline?: boolean
  variant?: 'default' | 'left_aligned'
}

const SectionHeader = ({
  byline,
  title,
  className = '',
  inline,
  variant = 'default'
}: SectionHeaderProps) => {
  if (!title) return null
  return (
    <div
      className={`${styles.sectionHeader} ${
        inline ? styles.sectionHeader_inline : ''
      } ${styles[variant]} ${className}`}
    >
      {byline && <p className={styles.sectionHeader__byline}>{byline}</p>}
      {title && <h2>{htmr(title)}</h2>}
    </div>
  )
}

export default SectionHeader
