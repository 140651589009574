import { ButtonLink } from '@components/ui/Button'
import styles from './styles.module.scss'
import {
  documentToReactComponents,
  Options
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document } from '@contentful/rich-text-types'

function Paragraph({ text }: { text: string }) {
  return text === '' ? null : <p>{text}</p>
}

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: node => {
      return node.content.map(childNode => {
        if (childNode.nodeType === 'text') {
          const { value } = childNode || {}
          return <Paragraph text={value} />
        }
        if (childNode.nodeType === 'embedded-entry-inline') {
          const { buttonStyle, text, uri } = childNode.data.target.fields || {}
          return (
            <ButtonLink
              href={uri}
              variant={buttonStyle}
              classes={styles.FeatureCards__contentButton}
            >
              {text}
            </ButtonLink>
          )
        }
        return null // Ensure a fallback for other node types
      })
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { url, details, contentType } = node.data.target.fields.file

      if (contentType === 'image/svg+xml') {
        return (
          <div
            className={styles.svgIcon}
            style={
              {
                maskImage: `url(${url})`,
                WebkitMaskImage: `url(${url})`,
                '--svgIcon-width': `${details.image.width}px`,
                '--svgIcon-height': `${details.image.height}px`
              } as React.CSSProperties
            }
          />
        )
      }

      return (
        <img
          src={url}
          alt={node.data.target.fields.title}
          width={details.size.width}
          height={details.size.height}
        />
      )
    }
  }
}

type FeatureCardsCopyProps = {
  copy: Document[]
}

export default function FeatureCardsCopy({ copy }: FeatureCardsCopyProps) {
  return (
    <div className={styles.FeatureCards__copy}>
      {copy.map(node => documentToReactComponents(node, options))}
    </div>
  )
}
