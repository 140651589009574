import { Card, Cards, OnThumbnailClick } from '../../types'
import styles from './styles.module.scss'

type TabVariant = 'default' | 'minimal'

type FeatureCardsTabsProps = {
  cards: Cards
  selectedCard: number
  setSelectedCard: (index: number) => void
  onThumbnailClick: OnThumbnailClick
  variant: TabVariant
}

type FeatureCardsTabsButtonProperties = {
  card: Card
  selectedCard: number
  setSelectedCard: (index: number) => void
  onThumbnailClick: OnThumbnailClick
  variant: TabVariant
  disabled?: boolean
}

function FeatureCardsTabsButton({
  card,
  disabled = false,
  selectedCard,
  setSelectedCard,
  onThumbnailClick,
  variant = 'default'
}: FeatureCardsTabsButtonProperties) {
  if (!card.thumbnail.image.url) {
    return null
  }

  return (
    <button
      className={`${styles.FeatureCards__button} ${
        disabled ? styles.FeatureCards__buttonMinimal : ''
      } ${card.id === selectedCard && !disabled ? styles.selected : ''}`}
      onClick={ev => {
        setSelectedCard(card.id)
        onThumbnailClick(ev, card.id)
      }}
      disabled={disabled}
    >
      <img
        className={`${styles.FeatureCards__icon} ${
          disabled ? styles.FeatureCards__iconMinimal : ''
        } ${styles[variant]}`}
        src={card.thumbnail.image.url}
        alt={card.thumbnail.label}
      />
      {!disabled && <p>{card.thumbnail.label}</p>}
    </button>
  )
}

export default function FeatureCardsTabs({
  cards,
  selectedCard,
  setSelectedCard,
  onThumbnailClick,
  variant = 'default'
}: FeatureCardsTabsProps) {
  return (
    <div className={styles.FeatureCards__buttons}>
      {cards.length === 1 ? (
        <FeatureCardsTabsButton
          card={cards[selectedCard]}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          onThumbnailClick={onThumbnailClick}
          variant="minimal"
          disabled={true}
        />
      ) : (
        cards.map(card => (
          <FeatureCardsTabsButton
            card={card}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            onThumbnailClick={onThumbnailClick}
            variant={variant}
          />
        ))
      )}
    </div>
  )
}
