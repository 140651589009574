import React, { useRef } from 'react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import styles from './styles.module.scss'
import htmr from 'htmr'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { isBrowser } from '@helpers/env'
import { useMediaQuery } from '@helpers/hooks'
import { BREAKPOINT_SIZES } from '@consts/index'
import classNames from 'classnames'

if (isBrowser()) {
  gsap.registerPlugin(ScrollTrigger)
}

type SectionHeaderProps = {
  title: string
  className?: string
  animated?: boolean
  showBorder?: boolean
  borderColor?: 'blue' | 'white' | 'orange'
}

const SectionHeadline = ({
  title,
  className = '',
  showBorder = false,
  borderColor = 'blue',
  animated = false
}: SectionHeaderProps) => {
  const isTabletUp = useMediaQuery({
    minWidth: BREAKPOINT_SIZES.TABLET,
    initialState: false
  })
  const variant = animated ? 'animated' : 'default'
  const titleRef = useRef<HTMLHeadingElement | null>(null)
  const titleText = title ? htmr(title) : null

  const classes = classNames({
    [styles.sectionHeadline__withBorder]: showBorder,
    [styles.sectionHeadline__borderBlue]: showBorder && borderColor === 'blue',
    [styles.sectionHeadline__borderWhite]:
      showBorder && borderColor === 'white',
    [styles.sectionHeadline__borderOrange]:
      showBorder && borderColor === 'orange'
  })

  useGSAP(() => {
    if (titleRef.current) {
      gsap
        .timeline({
          defaults: {
            ease: 'none'
          },
          scrollTrigger: {
            trigger: titleRef.current,
            start: isTabletUp ? 'bottom bottom' : 'top 80%',
            scrub: true
          }
        })
        .to(titleRef.current, { xPercent: isTabletUp ? -10 : -20 }, 0)
    }
  })

  return (
    <div
      className={`${styles.sectionHeadline} ${classes} ${styles[variant]} ${className}`}
    >
      <h2 className={styles.title} ref={animated ? titleRef : null}>
        <span>{titleText}</span>
        {animated && (
          <>
            <span>{titleText}</span>
            <span>{titleText}</span>
            <span>{titleText}</span>
          </>
        )}
      </h2>
    </div>
  )
}

export default SectionHeadline
